import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { up } from 'styled-breakpoints'

const Message = styled.span`
  font-size: 18px;
  line-height: 30px;
  white-space: pre-line;
  font-family: ${(props) => props.theme.fonts.MAN_light};
  hyphens: auto;

  ${up('sm')} {
    font-size: 20px;
    line-height: 35px;
  }

  ${up('md')} {
    font-size: 25px;
    line-height: 45px;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  padding-top: 30px;

  ${up('md')} {
    padding-top: 110px;
  }
`

const LeftImage = styled.div`
  position: relative;
  left: 0;
  top: 0;
  transform: translateX(-65%) translateY(0);
  display: none;
  z-index:1;

  ${up('md')} {
    display: block;
    position: absolute;
    width: 30%;
    margin-left: 9%;
  }
  
  ${up('lg')} {
    margin-left: 8%;
    max-width: 550px;
  }
}
`

const BottomImages = styled.div`
  height: 200px;
  position: relative;

  ${up('md')} {
    height: 250px;
  }

  ${up('lg')} {
    height: 275px;
  }
`

const RightImage = styled.div`
  position: absolute;
  bottom: -10%;
  right: -7.5vw;
  width: 100%;
  max-width: 250px;

  ${up('md')} {
    max-width: 300px;
  }

  ${up('lg')} {
    max-width: 340px;
  }
`

export const ECardMessage = ({ message }) => {
  return (
    <div className='container-fluid'>
      <InnerWrapper>
        <LeftImage>
          <StaticImage
            src='../assets/images/xmas_box2x.png'
            alt='Christmas Box'
          />
        </LeftImage>
        <div className='row'>
          <div className='offset-lg-2 col-lg-8 col-md-10 offset-md-1'>
            <Message>{message}</Message>
          </div>
        </div>

        <BottomImages>
          <RightImage>
            <StaticImage
              src='../assets/images/xmas_christmas_balls.png'
              alt='Christmas Box'
            />
          </RightImage>
        </BottomImages>
      </InnerWrapper>
    </div>
  )
}
