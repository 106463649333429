import { Headline } from './Headline'
import { Video } from './Video'
import React from 'react'
import styled from 'styled-components'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { ECardMessage } from './ECardMessage'
import GreetingsVideo from '../assets/videos/2024/MAN_Digital_Xmas_Card_16x9.mp4'
import GreetingsVideoWebM from '../assets/videos/2024/MAN_Digital_Xmas_Card_16x9.webm'

const Header = styled.div`
  margin-top: 110px;
  margin-bottom: 60px;
`

const VideoWrapper = styled.div``

export const ECardContent = ({
  firstName,
  lastName,
  message,
  videoAutoplay = false,
}) => {
  const { t } = useI18next()

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-10 offset-xxl-1'>
            <Header>
              <Headline as='h1' size='xxl'>
                <span
                  dangerouslySetInnerHTML={{ __html: t('eCard.headline') }}
                />
                {': '}
              </Headline>
            </Header>
          </div>
          <div
            style={{ marginTop: '-40px' }}
            className='col-lg-10 mb-3 offset-xxl-1'
          >
            <Headline
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
              as='h1'
              size='xxlCustom'
            >
              {firstName} {lastName}
            </Headline>
          </div>
        </div>

        <div className='row'>
          <div className='col-xxl-10 offset-xxl-1'>
            <VideoWrapper>
              <Video
                autoplay={videoAutoplay || false}
                sources={[
                  {
                    src: GreetingsVideoWebM,
                    type: 'video/webm',
                  },
                  {
                    src: GreetingsVideo,
                    type: 'video/mp4',
                  },
                ]}
              />
            </VideoWrapper>
          </div>
        </div>
      </div>
      <ECardMessage message={message} />
    </>
  )
}
