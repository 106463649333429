import React from 'react'
import styled from 'styled-components'
import { Headline } from './Headline'
import { Transition } from 'react-transition-group'
import logo_white_svg from '../assets/images/logo_white.svg'

const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: 'none' },
}

const Outer = styled.div`
  background: ${(props) => props.theme.color.anthracite800};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;

  #loading-snow canvas {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  h1 {
    color: white;
  }
`

const LoadingDots = styled.div`
  display: inline-block;

  &:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const LogoImg = styled.img`
  display: block;
  width: 100%;
  max-width: 325px;
  height: auto;
  vertical-align: middle;
  border-style: none;
  padding: 0 20px;
`

export const FullsizeLoadingScreen = ({ show }) => {
  return (
    <Transition in={show} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <Outer>

            <LogoImg src={logo_white_svg} />
            <LoadingWrapper>
              <Headline size='h1' as='h1'>
                Loading <LoadingDots />
              </Headline>
            </LoadingWrapper>
          </Outer>
        </div>
      )}
    </Transition>
  )
}
