import React, { useEffect, useRef } from 'react'

export const Video = ({ sources, autoplay, posterImage = null }) => {
  const videoElRef = useRef()

  // https://developer.chrome.com/blog/autoplay/
  useEffect(() => {
    if (!autoplay) return

    let hasInteracted = false

    window.addEventListener('click', playVideo)

    function playVideo() {
      if (hasInteracted) return

      hasInteracted = true

      try {
        videoElRef.current.play()
      } catch (e) {}
    }
  }, [autoplay])

  return (
    <video
      ref={videoElRef}
      controls={true}
      autoPlay={false}
      style={{ width: '100%' }}
      poster={posterImage}
    >
      {sources.map(({ src, type }, index) => (
        <source key={index} src={src} type={type} />
      ))}
    </video>
  )
}
