import React, { useEffect, useReducer } from 'react'
import { getECardById } from '../service/getECardById'
import { minimumTime } from '../utils/minimumTime'
import { FullsizeLoadingScreen } from './FullsizeLoadingScreen'
import { ECardContent } from './ECardContent'
import { useI18next } from 'gatsby-plugin-react-i18next'

const ACTIONS = {
  FETCH_CARD: 'FETCH_CARD',
  SET_ECARD: 'SET_ECARD',
  SET_404: 'SET_404',
}

const initialState = {
  ecard: null,
  isLoading: true,
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_CARD:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case ACTIONS.SET_ECARD:
      return {
        ...state,
        isLoading: false,
        error: null,
        eCard: action.payload,
      }
    case ACTIONS.SET_404:
      return {
        ...state,
        isLoading: false,
        error: 'eCard does not exist',
        eCard: null,
      }
    case ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Unknown error occurred',
        eCard: null,
      }
  }
}

export const ECardWithDataFetch = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { navigate } = useI18next()

  useEffect(() => {
    const fetch = async () => {
      dispatch({
        type: ACTIONS.FETCH_CARD,
      })

      let eCardRes = null

      try {
        eCardRes = await minimumTime(() => getECardById({ id }))
      } catch (e) {
        dispatch({
          type: ACTIONS.ERROR,
        })
      }

      if (eCardRes.error) {
        dispatch({
          type: ACTIONS.SET_404,
        })

        return
      }

      dispatch({
        type: ACTIONS.SET_ECARD,
        payload: eCardRes,
      })
    }

    fetch()
  }, [])

  useEffect(() => {
    if (state.error) {
      navigate('/')
    }
  }, [state.error])

  return (
    <>
      <FullsizeLoadingScreen show={state.isLoading} />

      {state.eCard && (
        <>
          <ECardContent
            firstName={state.eCard.firstName}
            lastName={state.eCard.lastName}
            message={state.eCard.message}
            videoAutoplay={true}
          />
        </>
      )}
    </>
  )
}
