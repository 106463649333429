import React, { useEffect } from 'react'
import { GlobalLayout } from '../layout/GlobalLayout'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { ECardWithDataFetch } from '../components/ECardWithDataFetch'
import { gtmTrackPageView } from '../service/tracking'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { OneTrustConnector } from '../service/OneTrustConnector'
import { PiwikConnector } from '../service/PiwikConnector'
import { AdobeTagManager } from "../service/AdobeTagManager";

const ECardPage = (props) => {
  const { eId } = queryString.parse(props.location.search)
  const { i18n } = useTranslation()

  useEffect(() => {
    gtmTrackPageView()
  }, [])

  return (
    <GlobalLayout headerWithSeperator={true}>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
        title='MAN | Christmas eCard'
      />
      <OneTrustConnector />
      <PiwikConnector />
      <AdobeTagManager />
      <ECardWithDataFetch id={eId} />
    </GlobalLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ECardPage
