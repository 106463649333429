import { API_URL } from './baseHttpService'

/**
 *
 * @param {{ id: string}} data
 * @returns {Promise<any>}
 */
export const getECardById = ({ id }) => {
  const headers = new Headers()
  headers.append('X-Api-Key', process.env.GATSBY_API_KEY)
  headers.append('content-type', `application/json`)

  const request = new Request(`${API_URL}/ecard/${id}`, {
    method: 'GET',
    headers,
  })

  return fetch(request).then((res) => res.json())
}
